import { IonCol, IonContent, IonGrid, IonImg, IonPage, IonRow } from "@ionic/react"
import React, { useEffect, useState } from "react"
import { fetchPageData } from "../../services/static_page"
import Footer from "../../components/Footer"

interface StaticPage {
    slug: any
}

const GivingTuesday: React.FC<StaticPage> = ({ slug }) => {

    const [page, setPage] = useState<any | null>(null)

    useEffect(() => {
        fetchData()

    }, [slug])

    const fetchData=async ()=>{
        let r= await fetchPageData(slug)
        setPage(r)
    }

    return (
        <IonPage className="pageContent">
            <IonContent>
                <IonGrid className="article-body">
                    {page && <>
                        <IonRow>
                            <IonCol offsetSm='1' sizeSm='10' className='article-header white_bg' style={{ padding: '0', borderRadius: '30px 30px 0px 0px',overflow:"hidden" }}>
                                <IonRow>
                                    <IonCol style={{padding:'0',margin:'0'}}>
                                        {page.featured_image_url &&<IonImg src={`${page.featured_image_url}`}></IonImg>}

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size="12"><h1 className="ion-padding">{page.title.rendered}</h1></IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <div className="ion-padding" dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>

                    </>}
                </IonGrid>
                <Footer></Footer>
            </IonContent>
        </IonPage>
    )

}

export default GivingTuesday