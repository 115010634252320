import { IonIcon } from "@ionic/react"
import { logoFacebook, logoLinkedin, logoTwitter,logoInstagram } from "ionicons/icons"
import React from "react"
import { AppInfo } from "../models/appInfo"



interface Props {
    location: string
}

const SocialLinks: React.FC<Props> = ({ location }) => {
    


    return (
        <>
            {
                location.indexOf("account") < 0 &&
                <div style={{ width: '100%', textAlign: 'right' }}>
                    {AppInfo.linkedinUrl.length>1 && <a href={AppInfo.linkedinUrl} target="_blank" rel="noopener noreferrer">
                    <IonIcon color="secondary" style={{margin:'0px 15px',cursor:'pointer'}} icon={logoLinkedin} size="large"></IonIcon>
                    </a>}
                    {AppInfo.facebookUrl.length>1 &&<a href={AppInfo.facebookUrl} target="_blank" rel="noopener noreferrer">
                    <IonIcon  color="secondary" style={{margin:'0px 15px',cursor:'pointer'}} icon={logoFacebook} size="large"></IonIcon>
                    </a>}
                    {AppInfo.twitterUrl.length>1 && <a href={AppInfo.twitterUrl} target="_blank" rel="noopener noreferrer">
                    <IonIcon color="secondary" style={{margin:'0px 15px',cursor:'pointer'}} icon={logoTwitter} size="large"></IonIcon>
                    </a>}
                    {AppInfo.instagramUrl.length>1 && <a href={AppInfo.instagramUrl} target="_blank" rel="noopener noreferrer">
                    <IonIcon color="secondary" style={{margin:'0px 15px',cursor:'pointer'}} icon={logoInstagram} size="large"></IonIcon>
                    </a>}
                </div>
            }
        </>
    )
}
export default SocialLinks