import { IonCol, IonGrid, IonImg, IonRow, IonToolbar, IonInput, IonButton, IonCard, IonText, IonSpinner, IonIcon } from "@ionic/react";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { translation } from "../models/translation";
import SocialLinks from "./SocialLinks";
import { staticLingualUrl } from "../utlities/multilingualUrl";
import subscribeUser from "../services/newsletter";
import { paperPlane } from "ionicons/icons";

const Footer: React.FC = () => {

    let location = useLocation().pathname;

    const siteText = translation();

    const [lan] = useState(siteText.lan);

    const [newletterEmail, setNewsletterEmail] = useState<string | null>(null)

    const [processing, setProcessing] = useState(false)


    const replaceYearInString = (originalString: string) => {
        const now = new Date();
        const genevaOffset = 60; // Geneva is UTC+1 in winter (standard time) and UTC+2 in summer (daylight saving time)
        const genevaTime = now.getTime() + (now.getTimezoneOffset() + genevaOffset) * 60 * 1000;
        const genevaNow = new Date(genevaTime);
        const currentYear = genevaNow.getFullYear();

        return originalString.replace("%&year&%", `${currentYear}`);
    }

    const submitUser = async (e: any) => {
        e.preventDefault()

        if (!newletterEmail) return
        setProcessing(true)
        let resp = await subscribeUser(newletterEmail)
        if (resp.message) {
            // Select the element

            console.log(resp.message)
            const confirmMessage = document.querySelector<HTMLDivElement>("#newsLetterSubscriptionConfirm");

            if (!confirmMessage) return; // Ensure element exists

            // Show the element
            confirmMessage.style.display = "block";

            // Set a timeout to hide the element after 3 seconds (3000 milliseconds)
            setProcessing(false)
            setTimeout(() => {
                confirmMessage.style.display = "none";
            }, 3000);

        } else {
            setProcessing(false)
        }

        // Clear the form
        const form = (e.target as HTMLFormElement);
        form.reset(); // Reset all fields in the form

    }

    return (
        <IonToolbar>
            <IonGrid className="site_footer">
                <IonRow>
                    <IonCol size="12" className="ion-padding-top">
                        <SocialLinks location={location}></SocialLinks>
                    </IonCol>
                </IonRow>
                <IonRow className="ion-align-items-center">
                    <IonCol className="ion-text-center ion-margin-bottom" size="12" >
                        <IonText color={"secondary"}>
                            <h4>{siteText.newsLetterFormTitle}</h4>
                        </IonText>
                        <form onSubmit={submitUser} style={{display:"inline-block"}}>
                            <div style={{display:"flex"}}>
                                <div className=" ion-padding-start ion-padding-end" style={{ maxWidth: "560px", borderRadius: "36px" }}>
                                    <IonInput mode="ios" onIonChange={(e: any) => setNewsletterEmail(e.detail.value)} shape="round" required type="email" placeholder={siteText.newsLetterEmailInputPlaceholder}></IonInput>
                                </div>
                                <IonButton  className="ion-margin-top" disabled={processing} type="submit" shape="round" color={"secondary"}>
                                    <IonText color={"light"}>
                                        {siteText.newsLetterSubmitButton}
                                    </IonText>
                                    <IonIcon color="light" slot="end" icon={paperPlane}></IonIcon>
                                </IonButton>
                            </div>
                            <div id="newsLetterSubscriptionConfirm" style={{ display: "none" }}>
                                <IonText color={"success"}>
                                    <p className="smallText">{siteText.newLetterSuccessMessage}</p>
                                </IonText>
                            </div>

                            {processing && <IonSpinner color={"primary"} name="lines"></IonSpinner>}
                        </form>
                    </IonCol>
                    <IonCol>

                        <IonRow className="ion-justify-content-around">
                            {/* <IonButton routerLink={`${lan}/${staticLingualUrl("about", lan)}`}>{siteText.menuAccountAboutTxt}</IonButton>
                        <IonButton routerLink={(!user || !user.code) ? `${lan}/articles` : `${lan}/account/articles`}>{siteText.menuAccountNewsTxt}</IonButton>*/}
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={`${lan}/${staticLingualUrl("about", lan)}`}>{siteText.menuAccountAboutTxt}</Link></p>
                            </IonCol>
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={lan + "/articles"}>{siteText.menuAccountNewsTxt}</Link></p>
                            </IonCol>
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={lan + "/privacy_policy"}>{siteText.footLnkPrivacyPolTxt}</Link></p>
                            </IonCol>
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={lan + "/impressum"}>{siteText.footLnkImpressTxt}</Link></p>
                            </IonCol>
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={lan + "/terms_of_use"}>{siteText.footLnkTouTxt}</Link>
                                </p>
                            </IonCol>
                            <IonCol size="auto" className="ion-padding">
                                <p><Link to={lan + "/faq"}>{siteText.footLnkFaqTxt}</Link>
                                </p>
                            </IonCol>

                        </IonRow>
                        <IonRow>
                            <IonCol className="providerLogosWrapper ion-padding-bottom">
                                <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/posfinance_logo.png`} ></IonImg>
                                <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/twint_logo.png`} ></IonImg>
                                <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/mc_symbol_opt_53_3x.png`} ></IonImg>
                                <IonImg className="providerLogo" src={`${import.meta.env.BASE_URL}images/visa.png`} ></IonImg>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>

                <IonRow>
                    <IonCol size="12" className="ion-padding ion-text-center">
                        <p>{replaceYearInString(siteText.footLnkCopyRights)}</p>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonToolbar>
    )

}
export default Footer;