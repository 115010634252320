import { User } from "../models/data";

export const userLink=(user:User|null)=>{
    if(!user){
        return ''
    }else{
        return 'account/'
    }
}

