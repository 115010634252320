const pages = [
    {
        name: "about",
        urls: {
            fr: "a_propos",
            de: "uber_uns",
            en: "about_us"
        }
    },
    {
        name: "donate",
        urls: {
            fr: "donner",
            de: "spenden",
            en: "donate"
        }
    },
    {
        name: "giftcard",
        urls: {
            fr: "carte_cadeau",
            de: "Geschenkkarten",
            en: "giftcard"
        }
    },
    {
        name: "givingtuesday",
        urls: {
            fr: "givingtuesdaychallenge-fr",
            de: "givingtuesdaychallenge-de",
            en: "givingtuesdaychallenge-en"
        }
    }
];

type Language = 'fr' | 'de' | 'en';

function isLanguage(lang: string): lang is Language {
    return ['fr', 'de', 'en'].includes(lang);
}

export const staticLingualUrl = (identifier: string, lan: string) => {
    const sanitizedLan = lan.replace("/", "");
    
    if (!isLanguage(sanitizedLan)) {
        throw new Error(`Unsupported language: ${sanitizedLan}`);
    }

    const page = pages.find(m => m.name === identifier);
    if (page) {
        return page.urls[sanitizedLan];
    }
};

export const isStaticLingualPage = (path: string, lan: string, targetLanKey: Language) => {
    const sanitizedLan = lan.replace("/", "");

    if (!isLanguage(sanitizedLan)) {
        throw new Error(`Unsupported language: ${sanitizedLan}`);
    }

    const lanPaths = pages.map(m => m.urls[sanitizedLan]);

    let foundPath = "";
    lanPaths.forEach((m, l) => {
        if (path.indexOf(m) > 0) {
            foundPath = path.replace(m, pages[l].urls[targetLanKey]);
            foundPath = foundPath.replace(lan, targetLanKey);
        }
    });
    return foundPath;
};

export default {};
