import Urls from "./url";

export const fetchPageData = async (pageSlug: string) => {
    try {
        const response = await fetch(Urls("staticPage", pageSlug));
        const data = await response.json();

        if (data.length > 0) {
            const page = data[0];

            // Now the featured media is already embedded in the response, no need for a second fetch
            if (page._embedded && page._embedded['wp:featuredmedia']) {
                page.featured_image_url = page._embedded['wp:featuredmedia'][0].source_url;
            }else{
                page.featured_image_url=""
            }
            return (page);
        } else {
            return (null); // No page found
        }
    } catch (error) {
        console.error("Error fetching the page:", error);
    }
};