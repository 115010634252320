export   const  storeLanguage  = (lan:any)=>{
    localStorage.setItem('userLan',lan)
    return {done:true}
}
export const retrieveLanguage= ()=>{

    //fetchFromStorage

    let lan= localStorage.getItem('userLan')
    return lan
}
export default {};