import Urls from "./url"

/**
 * 
 * @returns 
 */
export const fetchOrgPages = async (categories: [any]) => {
    let catList = categories.join(',');
    const url = Urls('organizationUrl', catList);
    //console.log(catList)
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}
/**
 * 
 * @returns 
 */
export const fetchOrgPagesFiterable = async (categories: [any], lan: string) => {
    let catList = categories.join(',');
    const url = Urls('orgsCustomUrl', catList, lan);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchRandomOrgPages = async (lan?: string) => {

    let url = '';

    if (lan && lan.length>1) {

        url = Urls('randomPosts', 'organisationpage', '4',lan);

    } else {

        url = Urls('randomPosts', 'organisationpage', '4','');

    }
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchOrg = async (id: any) => {
    const url = Urls('organizationSingleUrl', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchBus = async (id: any) => {
    const url = Urls('businessSingleUrl', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchOrgBySlug = async (slug: any) => {
    const url = Urls('organizationSingleBySlugUrl', slug);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchOrgList = async () => {
    let resp = await fetch(Urls('organizationListUrl'));
    if (resp.ok) {
        let data = await resp.json()
        //console.log(data)
        let message = data.filter((el: any) => {
            if (!el.acf) return true
            if (!el.acf.inactive) return true
        })
        //console.log(message)
        return {
            message: message,
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}
export const fetchOrgById = async (id: any) => {
    const url = Urls('organizationSingUrl', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}
export const fetchOrgsWhereAdmin = async (id: any) => {
    const url = Urls('orgsWhereAdmin', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export default {}