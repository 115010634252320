import { IonCol, IonContent, IonGrid, IonPage, IonRow, IonText, IonLabel, IonInput, IonTextarea, IonButton, IonThumbnail } from "@ionic/react";
import { useEffect, useState } from "react";
import { useLocation, useHistory, } from "react-router";

import Footer from "../../components/Footer";
import { OptionPages } from '../../models/apiModels';
import { translation } from "../../models/translation";
import { fetchOptions } from "../../services/options_service";
import { User } from "../../models/data";
import { userLink } from "../../utlities/userLink";
import { fetchOrgById } from "../../services/org_service";
//import Meta from "../../components/Meta";

interface GiftCardHomeProps {
    user: User | null;
}

const OrgGiftCard: React.FC<GiftCardHomeProps> = ({ user }) => {

    const siteText = translation();
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    const [org, setOrg] = useState<any | null>(null)


    const pathName = location.pathname;
    const isAccountPage = () => {
        if (pathName.indexOf('account') < 0) return false;
        return true
    }


    const [siteOptions, setSiteOptions] = useState<OptionPages>();

    const getOptions = async () => {
        let resp: any = await fetchOptions();
        if (!resp.error) {
            let options: OptionPages = resp.message;
            setSiteOptions(options);
        }
    }
    useEffect(() => {
        getOptions()
    }, []);

    useEffect(() => {
        getOrg()
    }, [id])

    const getOrg = async () => {
        let org = await fetchOrgById(id);
        setOrg(org.message);
    }

    const startGifting = (e: any) => {
        e.preventDefault();
        const data = {
            gifting: true,
            name: e.target.name.value,
            fName: e.target.fName.value,
            email: e.target.email.value,
            message: e.target.message.value
        }
        const info = {
            from: "startPage",
            orgId: id,
            org: org,
            data: data,
        }
        history.push(`${siteText.lan}/${userLink(user)}donate_gift`, info);
        e.target.reset();
    }

    return (
        <IonPage className="pageContent">
            {/*siteText.lan === '/en' && <Meta url={window.location.href} description={`${siteOptions?.acf.gc_seo_title_en}`} title={`${siteOptions?.acf.gc_seo_title_en}`} shareImage={`${siteOptions?.acf.giftcard_image}`}></Meta>*/}
            {/*siteText.lan === '/fr' && <Meta url={window.location.href} description={`${siteOptions?.acf.gc_seo_title_fr}`} title={`${siteOptions?.acf.gc_seo_title_fr}`} shareImage={`${siteOptions?.acf.giftcard_image}`}></Meta>*/}
            {/*siteText.lan === '/de' && <Meta url={window.location.href} description={`${siteOptions?.acf.gc_seo_title_de}`} title={`${siteOptions?.acf.gc_seo_title_de}`} shareImage={`${siteOptions?.acf.giftcard_image}`}></Meta>*/}
            {org && <IonContent>
                <IonGrid className={!isAccountPage() ? 'sw_mb_md' : ''}>
                    <IonRow className="headerImageOnly" style={{ backgroundImage: `url(${siteOptions?.acf.giftcard_image_alt})` }}>

                    </IonRow>
                    <IonRow>
                        {siteOptions &&
                            <IonCol className="ion-text-center" offsetSm="1" offsetMd="2" sizeSm="10" sizeMd="8" >
                                <h1 style={{ display: "inline-block" }}>{siteText.giftCardTitleOrg}</h1>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {siteText.lan === '/en' && <img style={{ height: "35px" }} className="ion-margin-end" src={org.pages.en.logo} />}
                                    {siteText.lan === '/fr' && <img style={{ height: "35px" }} className="ion-margin-end" src={org.pages.fr.logo} />}
                                    {siteText.lan === '/de' && <img style={{ height: "35px" }} className="ion-margin-end" src={org.pages.de.logo} />}
                                    <h3 dangerouslySetInnerHTML={{ __html: org.org.title }}></h3>
                                </div>
                                {siteText.lan === '/en' && <div dangerouslySetInnerHTML={{ __html: siteOptions?.acf.gcard_heading_english_alt! }}></div>}
                                {siteText.lan === '/fr' && <div dangerouslySetInnerHTML={{ __html: siteOptions?.acf.gcard_heading_french_alt! }}></div>}
                                {siteText.lan === '/de' && <div dangerouslySetInnerHTML={{ __html: siteOptions?.acf.gcard_heading_german_alt! }}></div>}
                            </IonCol>
                        }
                    </IonRow>
                    <IonRow>
                        <IonCol offsetSm="1" offsetMd="2" sizeSm="10" sizeMd="8">
                            <form onSubmit={startGifting}>
                                <p>{siteText.giftCardBeneficiaryInfoLabel}</p>
                                <IonInput name="name" required className="ion-margin-top ion-margin-bottom" placeholder={siteText.giftCardBeneficiaryNamePlaceholder}></IonInput>
                                <IonInput name="fName" required className="ion-margin-bottom" placeholder={siteText.giftCardBeneficiaryFirstNamePlaceholder}></IonInput>
                                <IonInput name="email" type="email" required className="ion-margin-bottom" placeholder={siteText.giftCardBeneficiaryEmailPlaceholder}></IonInput>
                                <IonTextarea defaultValue={""} labelPlacement="stacked" label={siteText.giftCardMessagePlaceholder} aria-placeholder={siteText.giftCardMessageLabel} name="message" placeholder={siteText.giftCardMessageLabel}></IonTextarea>
                                {/*!user &&
                                    <div>
                                        <IonButton routerLink={`${siteText.lan}/signup`} color="primary"><IonText color="light">{siteText.giftCardSignUpButton}</IonText></IonButton>
                                        <IonButton routerLink={`${siteText.lan}/signin`} color="secondary"><IonText color="light">{siteText.giftCardSignInButton}</IonText></IonButton>
                                    </div>
                                */}
                                <IonButton color="primary" className="ion-margin-top ion-margin-bottom" type="submit"><IonText color="light">{siteText.giftCardDonateButton}</IonText> </IonButton>
                            </form>
                        </IonCol>

                    </IonRow>

                </IonGrid>
                <Footer></Footer>
            </IonContent>}
            {!org && <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="ion-text-center">
                            {siteText.lan === '/en' && <p>Please specify an association</p>}
                            {siteText.lan === '/fr' && <p>Veuillez préciser une association</p>}
                            {siteText.lan === '/de' && <p>Bitte geben Sie eine Vereinigung an</p>}

                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>}

        </IonPage >)
}

export default OrgGiftCard;

