import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonImg, IonPage, IonRow, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Footer from '../../components/Footer';
import { User } from '../../models/data';
import { translation } from '../../models/translation';
import { fetchFundBySlug } from '../../services/fund_service';
import { userLink } from '../../utlities/userLink';


interface FundDetailProps {
    user: User | any
}

const FundDetail: React.FC<FundDetailProps> = ({ user }) => {

    const svgUrl = `${import.meta.env.BASE_URL}images/svg/`;

    const history = useHistory()

    const siteText = translation();

    const lan = siteText.lan.replace("/", "")

    const pathName = useLocation().pathname;
    //__
    const isAccountPage = () => {
        if (pathName.indexOf('account') < 0) return false;
        return true
    }

    const navigate = (e: string) => {

        if (isAccountPage()) {
            history.push(`/${lan}/account/fundraisers/${e}`);
        } else {
            history.push(`/${lan}/fundraisers/${e}`)
        }
    }
    const loginFirst = () => {
        history.push({ pathname: `/${lan}/signin`, state: { destination: `/account/donate_direct_to_org_V2?id=${fund.id}` }})
    }

    const [fund, setFund] = useState<any>(null);


    const { id } = useParams<{ id: string }>();


    // fetch data
    useEffect(() => {
        let mounted = true;
        fetchFundBySlug(id).then(
            ({ message }) => {
                if (message) {
                
                    setFund(message[0]);

                }
            }
        )
        return () => {
            mounted = false;
            setFund(null)
        };
    }, [id])


    const goToDonatePage = () => {
        history.push(`/${lan}/${userLink(user)}donate_direct_to_org_V2?id=${fund.acf.related_fund.ID}&type=fund`)

    }



    return (
        <IonPage className='pageContent '>
            <IonContent>
                <IonGrid className='article-body'>
                    <IonRow>
                        <IonCol>
                            {
                                fund &&
                                <IonRow>
                                    <IonCol offsetSm='1' sizeSm='10'>
                                        {
                                            fund.acf.image &&
                                            <IonRow>
                                                <IonCol className='article-header white_bg ' style={{ padding: '0' }}>
                                                    <IonImg src={fund.acf.image}></IonImg>
                                                </IonCol>
                                            </IonRow>
                                        }
                                        <IonRow>
                                            <IonCol className='white_bg ion-padding'>
                                                <h1 dangerouslySetInnerHTML={{__html:fund.title.rendered}}></h1>
                                            <IonButton  onClick={_ => goToDonatePage()} mode='ios' ><IonIcon slot='start' color='light' src={`${svgUrl}${'fundraise_2.svg'}`}></IonIcon><IonText color='light'>{siteText.charityDetailPrimary}</IonText></IonButton>
                                            <div className='ion-margin-top' dangerouslySetInnerHTML={{__html:fund.acf.description}}></div>
                                            </IonCol>
                                        </IonRow>
                                    </IonCol>
                                </IonRow>
                            }
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <Footer></Footer>
            </IonContent >
        </IonPage >
    );
};

export default FundDetail;
