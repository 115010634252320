import Urls from "./url"

/**
 * 
 * @returns 
 */
export const fetchFundPages = async () => {
    //console.log(catList)
    let resp = await fetch(Urls('fundsUrl')).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}
/**
 * 
 * @returns 
 */
export const fetchFundPagesByLan = async (lan: string) => {
    const url = Urls('fundsCustomUrl',lan);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchRandomOrgPages = async (lan?: string) => {

    let url = '';

    if (lan && lan.length>1) {

        url = Urls('randomPosts', 'organisationpage', '4',lan);

    } else {

        url = Urls('randomPosts', 'organisationpage', '4','');

    }
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchFundTheme = async (id: any) => {
    const url = Urls('FundThemeUrl', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchBus = async (id: any) => {
    const url = Urls('businessSingleUrl', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchFundBySlug = async (slug: any) => {
    const url = Urls('fundSingleBySlugUrl', slug);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchFundList = async () => {
    let resp = await fetch(Urls('fundListUrl'));
    if (resp.ok) {
        let data = await resp.json()
        //console.log(data)
        let message = data.filter((el: any) => {
            if (!el.acf) return true
            if (!el.acf.inactive) return true
        })
        //console.log(message)
        return {
            message: message,
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export const fetchOrgsWhereAdmin = async (id: any) => {
    const url = Urls('orgsWhereAdmin', id);
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export default {}