export const safeSubstring = (str: string, length: number): string => {
  if (str.length <= length) {
      return str;
  }
  const index = str.indexOf(' ', length);
  if (index === -1) {
      return str;
  }
  return str.substring(0, index);
};
