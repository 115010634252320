import Urls from './url';

export const fetchOptions = async () => {
    const url = Urls('optionsUrl');
    let resp = await fetch(url).then((resp) => {
        return resp
    })

    if (resp.ok) {
        return {
            message: await resp.json(),
            error: null
        }
    } else {
        return {
            message: null,
            error: resp.statusText
        }
    }
}

export default {};