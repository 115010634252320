import translationCopy from "./translationCopy";
import TranslationCopy from "./translationModel";


let pathname = window.location.pathname;

const getLan = (pathname: string) => {

    let languages: string[] = ['/en', '/fr', '/de'];
    let siteLanguage: string = '';
    languages.every((lang) => {
        if (pathname.indexOf(lang) === 0) {
            siteLanguage = lang
            return false;
        }
        return true
    });
    return siteLanguage;

}


const translationSource = translationCopy;

const convert = (source: any, lan: any) => {
    let objectWithGroupByName: any = {};

    source.forEach((item: any, index: any, arr: any) => {
        if (lan.length > 0) {
            let cleanLan = lan.replace("/", "");
            Object.assign(objectWithGroupByName, { [item.name]: item[cleanLan] })
        } else {
            Object.assign(objectWithGroupByName, { [item.name]: item.en })

        }
    })
    if (lan.length > 0) {
        Object.assign(objectWithGroupByName, { lan: lan })
    } else {
        Object.assign(objectWithGroupByName, { lan: '/en' })
    }
    Object.assign(objectWithGroupByName,{path:pathname})
    return (objectWithGroupByName);

}


export const translation = (): TranslationCopy => {
    return (convert(translationSource, getLan(pathname)))
}

