import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonPage, IonRow,IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer';
import { OptionPages } from '../../models/apiModels';
import { translation } from '../../models/translation';
import { fetchOptions } from '../../services/options_service';
import { fetchFundPagesByLan } from '../../services/fund_service';
import { safeSubstring } from '../../utlities/safeSubstring';
import { User } from '../../models/data';
import { userLink } from '../../utlities/userLink';
//import Meta from '../../components/Meta';

interface ThematicFundsProps {
    user: User | any
}

const ThematicFunds: React.FC<ThematicFundsProps> = ({ user }) => {


    const siteText = translation();
    const lan = siteText.lan.replace("/", "")

    const [siteOptions, setSiteOptions] = useState<OptionPages>();

    const [funds, setFunds] = useState([])


    useEffect(() => {
        getInitData()
        getOptions();

    }, [])

    const getOptions = async () => {
        let resp: any = await fetchOptions();
        if (!resp.error) {
            let options: OptionPages = resp.message;
            setSiteOptions(options);
        }
    }
    const getInitData = async () => {
        await fetchFundPagesByLan(lan).then(m => {
            setFunds(m.message)
        })
    }







    const descriptionHtml = (html: any) => {
        html = `${safeSubstring(html, 380)} ...`
        return { __html: html }
    }

    return (
        <IonPage>

            <IonContent>
                <IonGrid>
                    <IonRow className='sw_sp_md'>
                        <IonCol offsetSm='1' sizeSm='10'>
                            {lan === "en" && <>
                                <h1 dangerouslySetInnerHTML={{ __html: `+${siteOptions?.acf.themefundpage_heading_description.en?.heading ?? ''}` }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: siteOptions?.acf.themefundpage_heading_description.en?.description ?? '' }}></p>
                            </>}
                            {lan === "fr" && <>
                                <h1 dangerouslySetInnerHTML={{ __html: `+${siteOptions?.acf.themefundpage_heading_description.fr?.heading ?? ''}` }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: siteOptions?.acf.themefundpage_heading_description.fr?.description ?? '' }}></p>
                            </>}
                            {lan === "de" && <>
                                <h1 dangerouslySetInnerHTML={{ __html: `+${siteOptions?.acf.themefundpage_heading_description.de?.heading ?? ''}` }}></h1>
                                <p dangerouslySetInnerHTML={{ __html: siteOptions?.acf.themefundpage_heading_description.de?.description ?? '' }}></p>
                            </>}
                        </IonCol>
                    </IonRow>
                    <IonRow className=''>
                        <IonCol>
                            <IonRow className=''>
                                {

                                    funds.length > 0 ?

                                        funds.map((fund: any, key: any) => {
                                            return (<IonCol sizeXs="12" sizeSm='4' sizeMd="3" key={key}>
                                                <IonCard>
                                                    <IonCardHeader>
                                                        <img style={{
                                                            width: "auto",
                                                            display: "block", // To ensure it behaves as a block element
                                                            marginLeft: '0',  // To center the image if needed
                                                            marginRight: 'auto'
                                                        }}
                                                            src={fund.acf ? `${fund.acf.image}` : ''}></img>
                                                        <IonCardTitle dangerouslySetInnerHTML={{ __html: fund.title }}></IonCardTitle>
                                                    </IonCardHeader>
                                                    <IonCardContent>
                                                        {/*<p className='ion-text-right'><a href={`${org.acf[lan].site_url}`}>web site</a></p>*/}
                                                        <div dangerouslySetInnerHTML={descriptionHtml(fund.acf.description)}></div>
                                                        <IonButton color={'medium'} routerLink={`/${lan}/funds/${fund.slug}`} className='ion-margin-bottom' size='small' fill='outline' >{siteText.detailsBtnTxt}</IonButton>
                                                        <IonButton routerLink={`/${lan}/${userLink(user)}donate_direct_to_org_V2?id=${fund.acf.related_fund.ID}&type=fund`} className='ion-margin-bottom' size='small' >
                                                            <IonText color={"light"}>
                                                                {siteText.charityDetailPrimary}
                                                            </IonText>
                                                        </IonButton>
                                                    </IonCardContent>
                                                </IonCard>
                                            </IonCol>
                                            )
                                        }

                                        )


                                        :
                                        <></>
                                }
                            </IonRow>
                        </IonCol>
                    </IonRow>

                </IonGrid>
                <Footer></Footer>
            </IonContent>
        </IonPage>
    );

};

export default ThematicFunds;
