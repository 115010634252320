import Urls from "./url";


const subscribeUser = async (email: string) => {
    try {
        const resp = await fetch(Urls('subscribeUserUrl'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email })
        })

        if (!resp.ok) { throw new Error(`Response status: ${resp.status}`) }
        
        return { message: await resp.json(), error: null }

    } catch (error) {
        console.log(error)
        return { message: null, error: error }

    }

}

export default subscribeUser;